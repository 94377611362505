import { Link } from 'gatsby';
import React from 'react';
import LegalLayout from '../components/layout/LegalLayout';

const terms = <Link to="/terms/">https://slab.com/terms/</Link>;

const OrderFormTerms: React.SFC = () => (
  <LegalLayout title="Order Form Terms and Conditions" noindex>
    <section>
      <h1>Order Form Terms and Conditions</h1>
      <p>September 15, 2021</p>
      <p>
        These Terms and Conditions will apply to the Order Form mutually
        executed between Slab, Inc. (“<u>Slab</u>”) and the party named on the
        Order Form (“<u>Customer</u>”). Subject to the Order Form and the below
        Order Form Terms and Conditions (together, this “<u>Agreement</u>”),
        Slab will provide the Services consistent with the selected plan
        indicated on the Order Form, as well as any Additional Features
        indicated on the Order Form. All capitalized terms not defined in these
        Terms and Conditions will have the definition given in the Order Form.
      </p>
    </section>
    <section>
      <h2>1. Definitions</h2>
      <ol>
        <li>
          “<u>Documentation</u>” means Slab-provided user documentation, in all
          forms, relating to the Services (e.g., user manuals, on-line help
          files).
        </li>
        <li>
          “<u>Services</u>” means the web-based knowledge management platform
          and services identified in the Order Form.
        </li>
        <li>
          “<u>User</u>” means a user of the Services as authorized by Customer.
        </li>
      </ol>
    </section>
    <section>
      <h2>2. Use of the Services</h2>
      <p>
        <strong>2.1</strong> <u>Use of the Services</u>. Subject to the terms
        and conditions of this Agreement, Slab grants to Customer a limited,
        worldwide, non-exclusive, non-transferable (except as permitted in
        Section 10.2) right during the term of this Agreement to use the
        Services solely in connection with Customer’s internal business
        operations and by the number of Users specified in the Order Form.
      </p>
      <p>
        <strong>2.2</strong> <u>Use of the Documentation</u>. Subject to the
        terms and conditions of this Agreement, Slab grants to Customer a
        limited, worldwide, non-exclusive, non-transferable (except as permitted
        in Section 10.2) license, without right of sublicense, during the term
        of this Agreement to reproduce, without modification, and internally use
        a reasonable number of copies of the Documentation solely in connection
        with use of the Services in accordance with this Agreement.
      </p>
      <p>
        <strong>2.3</strong> <u>Use Restrictions</u>. Except as otherwise
        explicitly provided in this Agreement or as may be expressly permitted
        by applicable law, Customer will not, and will not permit or authorize
        third parties to: a) rent, lease, or otherwise permit third parties to
        use the Services or Documentation; b) use the Services to provide
        services to third parties (e.g., as a service bureau); c) use the
        Services for any benchmarking activity or in connection with the
        development of any competitive product or for the purpose of bringing an
        infringement claim against Slab; nor d) circumvent or disable any
        security or other technological features or measures of the Services.
      </p>
      <p>
        <strong>2.4</strong> <u>Compliance with Laws</u>. Customer will use the
        Services and Documentation in compliance with all applicable laws and
        regulations.
      </p>
      <p>
        <strong>2.5</strong> <u>Protection against Unauthorized Use</u>.
        Customer will use reasonable efforts to prevent any unauthorized use of
        the Services and Documentation and immediately notify Slab in writing of
        any unauthorized use that comes to Customer’s attention. If there is
        unauthorized use by anyone who obtained access to the Services directly
        or indirectly through Customer, Customer will take all steps reasonably
        necessary to terminate the unauthorized use. Customer will cooperate and
        assist with any actions taken by Slab to prevent or terminate
        unauthorized use of the Services or Documentation.
      </p>
      <p>
        <strong>2.6</strong> <u>Reservation of Rights</u>. Slab grants to
        Customer a limited right to use the Services and Documentation under
        this Agreement. Customer will not have any rights to the Services or
        Documentation except as expressly granted in this Agreement. Slab
        reserves to itself all rights to the Services and Documentation not
        expressly granted to Customer in accordance with this Agreement.
      </p>
      <p>
        <strong>2.7</strong> <u>Feedback</u>. If Customer provides any feedback
        to Slab concerning the functionality and performance of the Services
        (including identifying potential errors and improvements), Customer
        hereby grants to Slab a non-exclusive, perpetual, irrevocable,
        royalty-free license to use any such feedback for any purpose. For
        avoidance of doubt, any feedback is provided “as is” and does not
        include any User Content.
      </p>
      <p>
        <strong>2.8</strong> <u>User Terms</u>. Users’ use of the Services will
        be subject to the Terms of Use available at {terms}. If there is any
        conflict between the Terms of Use and these Order Form Terms and
        Conditions, the provisions in these Order Form Terms and Conditions will
        control.
      </p>
      <p>
        <strong>2.9</strong> <u>User Content</u>. Customer acknowledges that
        Users will be able to create, transmit, publish or display information
        (such as data files, written text, computer software, music, audio files
        or other sounds, photographs, videos or other images) through use of the
        Services. All such information is referred to below as &quot;
        <u>User Content</u>.&quot; Customer hereby grants to Slab a worldwide,
        royalty-free, and non-exclusive license to reproduce, adapt, modify,
        translate, publish, and distribute such User Content for the sole
        purpose of enabling Slab to provide the Services. Customer agrees that
        Customer is solely responsible for (and that Slab has no responsibility
        to you or to any third party for) any User Content, and for the
        consequences of Customer’s or any of Customer’s Users’ actions
        (including any loss or damage which Slab may suffer) in connection with
        such User Content. Customer will: (a) be solely responsible for the
        nature, quality and accuracy of the User Content of Customer and its
        users; (b) ensure that the User Content (including the storage or
        transmission thereof) of Customer and its Users complies with this
        Agreement, the Terms of Use, and any and all applicable laws, and
        regulations; and (c) promptly handle and resolve any notices and claims
        relating to the User Content of Customer and its Users, including any
        notices sent to Customer by any person claiming that any User Content
        violates any person&apos;s rights, such as take-down notices pursuant to
        the Digital Millennium Copyright Act and any other notices. Customer
        agrees that Slab will have no liability of any kind as a result of the
        deletion of any User Content in accordance with this Agreement or the
        Terms of Use.
      </p>
    </section>
    <section>
      <h2>3. Fees and Payment</h2>
      <p>
        The Parties agree that the pricing and payment terms on the Order Form
        will govern. Customer shall pay Slab the fees set forth on the Order
        Form (“<u>Fees</u>”) within thirty (30) days of the date of Slab’s
        applicable and undisputed invoice. All Fees are non-refundable and shall
        be paid in U.S. Dollars. Notwithstanding the foregoing, if Customer
        terminates this Agreement as a result of a material breach by Slab,
        Customer will receive a pro rata refund of Fees paid to Slab for the
        remaining unused portion of the Services. Customer shall pay any
        applicable sales, use, value added, surtax and personal property taxes,
        duties, fees and other governmental assessments or charges arising out
        of this Agreement and the transactions contemplated in this Agreement,
        excluding taxes based on Slab’s income.
      </p>
    </section>
    <section>
      <h2>4. Term and Termination</h2>
      <p>
        <strong>4.1</strong> <u>Term</u>. This Agreement will commence upon the
        Effective Date and continue for one year unless this Agreement is
        terminated earlier in accordance with the terms of this Agreement. This
        Agreement will automatically renew for additional successive one-year
        terms unless at least 30 days before the end of the then-current term
        either party provides written notice to the other party that it does not
        want to renew.
      </p>
      <p>
        <strong>4.2</strong> <u>Termination</u>. Either party may terminate this
        Agreement if the other party does not cure its material breach of this
        Agreement within 30 days of receiving written notice of the material
        breach from the non-breaching party. Termination in accordance with this
        Section 4.2 will take effect when the breaching party receives written
        notice of termination from the non-breaching party, which notice must
        not be delivered until the breaching party has failed to cure its
        material breach during the 30-day cure period. If Customer fails to
        timely pay any fees, Slab may, without limitation to any of its other
        rights or remedies, suspend performance of the Services until it
        receives all amounts due.
      </p>
      <p>
        <strong>4.3</strong> <u>Post-Termination Obligations</u>. If this
        Agreement is terminated for any reason, a) Customer will pay to Slab any
        fees or other amounts that have accrued prior to the effective date of
        the termination, b) any and all liabilities accrued prior to the
        effective date of the termination will survive, and c) Customer will
        provide Slab with a written certification signed by an authorized
        Customer representative certifying that all use of the Services and
        Documentation by Customer has been discontinued.
      </p>
    </section>
    <section>
      <h2>5. Confidentiality</h2>
      <p>
        Each party shall keep confidential all information identified by the
        other party as confidential or proprietary (“
        <u>Confidential Information</u>”). Each party shall keep and instruct
        its employees and agents to keep Confidential Information confidential
        by using at least the same care and discretion as used with that party’s
        own confidential information, but in no case less than a prudent and
        reasonable standard of care. Information shall not, with respect to a
        party, be subject to such confidentiality obligations if it is or
        becomes: (a) publicly available through no fault of the receiving party,
        (b) known to such party prior to the time of disclosure by another
        party, (c) lawfully and rightfully disclosed to such party by a third
        party on a non-confidential basis, (d) developed by such party without
        reference to Confidential Information or (e) required to be disclosed by
        law, provided that the receiving party promptly provide notice to the
        disclosing party of such request or requirement so the disclosing party
        may seek appropriate protective orders.
      </p>
    </section>
    <section>
      <h2>6. Warranties and Disclaimer</h2>
      <p>
        <strong>6.1</strong> <u>Mutual Warranties</u>. Each party represents and
        warrants to the other that: a) this Agreement has been duly executed and
        delivered and constitutes a valid and binding agreement enforceable
        against such party in accordance with its terms; and b) no authorization
        or approval from any third party is required in connection with such
        party’s execution, delivery, or performance of this Agreement.
      </p>
      <p>
        <strong>6.2</strong> <u>Slab Warranties</u>. Slab represents and
        warrants that it will provide the Services in a professional and
        workmanlike manner. Slab warrants that, to its knowledge, the Service is
        and will be free from any software viruses, worms, Trojan horses or
        other code, files, or scripts intended to do harm (“
        <u>Malicious Code</u>”) and that Slab will not knowingly or
        intentionally transmit Malicious Code to Customer.
      </p>
      <p>
        <strong>6.3</strong> <u>Disclaimer</u>. EXCEPT FOR THE EXPRESS
        REPRESENTATIONS AND WARRANTIES STATED IN THIS SECTION 6, SLAB MAKES NO
        ADDITIONAL REPRESENTATION OR WARRANTY OF ANY KIND WHETHER EXPRESS,
        IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY
        MATTER WHATSOEVER. SLAB EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, ACCURACY,
        TITLE, AND NON-INFRINGEMENT. SLAB DOES NOT WARRANT AGAINST INTERFERENCE
        WITH THE ENJOYMENT OF THE SERVICES. SLAB DOES NOT WARRANT THAT THE
        SERVICES ARE ERROR-FREE OR THAT OPERATION OF THE SERVICES WILL BE SECURE
        OR UNINTERRUPTED. SLAB DOES NOT WARRANT THAT ANY INFORMATION PROVIDED
        THROUGH THE Services IS ACCURATE OR COMPLETE OR THAT ANY INFORMATION
        PROVIDED THROUGH THE SERVICES WILL ALWAYS BE AVAILABLE. SLAB EXERCISES
        NO CONTROL OVER AND EXPRESSLY DISCLAIMS ANY LIABILITY ARISING OUT OF OR
        BASED UPON THE RESULTS OF CUSTOMER’S USE OF THE SERVICES.
      </p>
    </section>
    <section>
      <h2>7. Intellectual Property Infringement</h2>
      <p>
        <strong>7.1</strong> <u>Defense of Infringement Claims</u>. Slab will,
        at its expense, either defend Customer from or settle any claim,
        proceeding, or suit (“<u>Claim</u>”) brought by a third party against
        Customer alleging that Customer’s use of the Services infringes or
        misappropriates any patent, copyright, trade secret, trademark, or other
        intellectual property right during the term of this Agreement if: a)
        Customer gives Slab prompt written notice of the Claim; b) Customer
        grants Slab full and complete control over the defense and settlement of
        the Claim; c) Customer provides assistance in connection with the
        defense and settlement of the Claim as Slab may reasonably request; and
        d) Customer complies with any settlement or court order made in
        connection with the Claim (e.g., relating to the future use of any
        infringing Services). Customer will not defend or settle any Claim
        without Slab’s prior written consent. Customer will have the right to
        participate in the defense of the Claim at its own expense and with
        counsel of its own choosing, but Slab will have sole control over the
        defense and settlement of the Claim.
      </p>
      <p>
        <strong>7.2</strong> <u>Indemnification of Infringement Claims</u>. Slab
        will indemnify Customer from and pay a) all damages, costs, and
        attorneys’ fees finally awarded against Customer in any Claim under
        Section 7.1; b) all out-of-pocket costs (including reasonable attorneys’
        fees) reasonably incurred by Customer in connection with the defense of
        a Claim under Section 7.1 (other than attorneys’ fees and costs incurred
        without Slab’s consent after Slab has accepted defense of the Claim);
        and c) all amounts that Slab agrees to pay to any third party to settle
        any Claim under Section 7.1.
      </p>
      <p>
        <strong>7.3</strong> <u>Exclusions from Obligations</u>. Slab will have
        no obligation under this Section 7 for any infringement or
        misappropriation to the extent that it arises out of or is based upon a)
        use of the Services in combination with other products or services if
        such infringement or misappropriation would not have arisen but for such
        combination; b) the Services are provided to comply with designs,
        requirements, or specifications required by or provided by Customer, if
        the alleged infringement or misappropriation would not have arisen but
        for the compliance with such designs, requirements, or specifications;
        c) use of the Services by Customer for purposes not intended or outside
        the scope of the license granted to Customer; d) Customer’s failure to
        use the Services in accordance with instructions provided by Slab, if
        the infringement or misappropriation would not have occurred but for
        such failure; or e) any modification of the Services not made or
        authorized in writing by Slab where such infringement or
        misappropriation would not have occurred absent such modification.
      </p>
      <p>
        <strong>7.4</strong> <u>Limited Remedy</u>. This Section 6 states Slab’s
        sole and exclusive liability, and Customer’s sole and exclusive remedy,
        for the actual or alleged infringement or misappropriation of any third
        party intellectual property right by the Services.
      </p>
    </section>
    <section>
      <h2>8. Customer Indemnification</h2>
      <p>
        <strong>8.1</strong> <u>Defense</u>. Except to the extent a claim is
        caused by Slab’s duty to indemnify Customer under Section 7, Customer
        will defend Slab from any actual or threatened third party Claim arising
        out of or based upon Customer’s breach of any of the provisions of this
        Agreement if: a) Slab gives Customer prompt written notice of the Claim;
        b) Slab grants Customer full and complete control over the defense and
        settlement of the Claim; c) Slab provides assistance in connection with
        the defense and settlement of the Claim as Customer may reasonably
        request; and d) Slab complies with any settlement or court order made in
        connection with the Claim. Slab will not defend or settle any Claim
        without Customer’s prior written consent. Slab will have the right to
        participate in the defense of the Claim at its own expense and with
        counsel of its own choosing, but Customer will have sole control over
        the defense and settlement of the Claim.
      </p>
      <p>
        <strong>8.2</strong> <u>Indemnification</u>. Customer will indemnify
        Slab from and pay a) all damages, costs, and attorneys’ fees finally
        awarded against Slab in any Claim under Section 8.1; b) all
        out-of-pocket costs (including reasonable attorneys’ fees) reasonably
        incurred by Slab in connection with the defense of a Claim under Section
        8.1 (other than attorneys’ fees and costs incurred without Customer’s
        consent after Customer has accepted defense of the Claim); and, c) all
        amounts that Customer agrees to pay to any third party to settle any
        Claim under Section 8.1.
      </p>
    </section>
    <section>
      <h2>9. Limitations of Liability</h2>
      <p>
        <strong>9.1</strong> <u>Disclaimer of Indirect Damages</u>.
        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THIS AGREEMENT,
        EACH PARTY WILL NOT, UNDER ANY CIRCUMSTANCES, BE LIABLE TO THE OTHER
        PARTY FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY DAMAGES
        ARISING OUT OF OR RELATED TO THIS AGREEMENT, INCLUDING BUT NOT LIMITED
        TO LOST PROFITS OR LOSS OF BUSINESS, EVEN IF SUCH OTHER PARTY IS
        APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING.
      </p>
      <p>
        <strong>9.2</strong> <u>Cap on Liability</u>. UNDER NO CIRCUMSTANCES
        WILL SLAB’S TOTAL LIABILITY OF ALL KINDS ARISING OUT OF OR RELATED TO
        THIS AGREEMENT (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS),
        REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS
        BASED ON CONTRACT, TORT, OR OTHERWISE, EXCEED THE TOTAL AMOUNT PAID BY
        CUSTOMER TO SLAB DURING THE 12 MONTHS IMMEDIATELY PRECEDING THE CLAIM
        (DETERMINED AS OF THE DATE OF ANY FINAL JUDGMENT IN AN ACTION).
      </p>
      <p>
        <strong>9.3</strong> <u>Independent Allocations of Risk</u>. EACH
        PROVISION OF THIS AGREEMENT THAT PROVIDES FOR A LIMITATION OF LIABILITY,
        DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE
        RISKS OF THIS AGREEMENT BETWEEN THE PARTIES. THIS ALLOCATION IS
        REFLECTED IN THE PRICING OFFERED BY SLAB TO CUSTOMER AND IS AN ESSENTIAL
        ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
        PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THIS
        AGREEMENT. THE LIMITATIONS IN THIS SECTION 9 WILL APPLY NOTWITHSTANDING
        THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY IN THIS
        AGREEMENT.
      </p>
    </section>
    <section>
      <h2>10. General</h2>
      <p>
        <strong>10.1</strong> <u>Relationship</u>. Slab will be and act as an
        independent contractor (and not as the agent or representative of
        Customer) in the performance of this Agreement.
      </p>
      <p>
        <strong>10.2</strong> <u>Assignability</u>. Neither party may assign its
        right, duties, and obligations under this Agreement without the other
        party’s prior written consent, which consent will not be unreasonably
        withheld or delayed, except that a party may assign this Agreement
        without the other party’s consent to a successor (including a successor
        by way of merger, acquisition, sale of assets, or operation of law) if
        the successor agrees to assume and fulfill all of the assigning party’s
        obligations under this Agreement.
      </p>
      <p>
        <strong>10.3</strong> <u>Subcontractors</u>. Slab may utilize a
        subcontractor or other third party to perform its duties under this
        Agreement so long as Slab remains responsible for all of its obligations
        under this Agreement.
      </p>
      <p>
        <strong>10.4</strong> <u>Notices</u>. Any notice required or permitted
        to be given in accordance with this Agreement will be effective if it is
        in writing and sent by certified or registered mail, or insured courier,
        return receipt requested, to the appropriate party at the address set
        forth on the signature page of this Agreement and with the appropriate
        postage affixed. Either party may change its address for receipt of
        notice by notice to the other party in accordance with this Section
        10.4. Notices are deemed given two business days following the date of
        mailing or one business day following delivery to a courier.
      </p>
      <p>
        <strong>10.5</strong> <u>Force Majeure</u>. Neither party will be liable
        for, or be considered to be in breach of or default under this Agreement
        on account of, any delay or failure to perform as required by this
        Agreement as a result of any cause or condition beyond its reasonable
        control, so long as that party uses all commercially reasonable efforts
        to avoid or remove the causes of non-performance.
      </p>
      <p>
        <strong>10.6</strong> <u>Governing Law</u>. This Agreement will be
        interpreted, construed, and enforced in all respects in accordance with
        the local laws of the State of California, U.S.A., without reference to
        its choice of law rules and not including the provisions of the 1980
        U.N. Convention on Contracts for the International Sale of Goods. Each
        party hereby irrevocably consents to the exclusive jurisdiction and
        venue of the federal, state, and local courts in San Francisco,
        California in connection with any action arising out of or in connection
        with this Agreement.
      </p>
      <p>
        <strong>10.7</strong> <u>Waiver</u>. The waiver by either party of any
        breach of any provision of this Agreement does not waive any other
        breach. The failure of any party to insist on strict performance of any
        covenant or obligation in accordance with this Agreement will not be a
        waiver of such party’s right to demand strict compliance in the future,
        nor will the same be construed as a novation of this Agreement.
      </p>
      <p>
        <strong>10.8</strong> <u>Severability</u>. If any part of this Agreement
        is found to be illegal, unenforceable, or invalid, the remaining
        portions of this Agreement will remain in full force and effect. If any
        material limitation or restriction on the use of the Services under this
        Agreement is found to be illegal, unenforceable, or invalid, Customer’s
        right to use the Services will immediately terminate.
      </p>
      <p>
        <strong>10.9</strong> <u>Counterparts</u>. This Agreement may be
        executed in any number of identical counterparts, notwithstanding that
        the parties have not signed the same counterpart, with the same effect
        as if the parties had signed the same document. All counterparts will be
        construed as and constitute the same agreement. This Agreement may also
        be executed and delivered by facsimile and such execution and delivery
        will have the same force and effect of an original document with
        original signatures.
      </p>
      <p>
        <strong>10.10</strong> <u>Entire Agreement</u>. This Agreement,
        including all Order Forms, is the final and complete expression of the
        agreement between these parties regarding Customer’s use of the
        Services. This Agreement supersedes, and the terms of this Agreement
        govern, all previous oral and written communications regarding these
        matters, all of which are merged into this Agreement, except that this
        Agreement does not supersede any prior nondisclosure or comparable
        agreement between the parties executed prior to this Agreement being
        executed, nor does it affect the validity of any agreements between the
        parties relating to professional services relating to the Services that
        Slab may provide. No employee, agent, or other representative of Slab
        has any authority to bind Slab with respect to any statement,
        representation, warranty, or other expression unless the same is
        specifically set forth in this Agreement. No usage of trade or other
        regular practice or method of dealing between the parties will be used
        to modify, interpret, supplement, or alter the terms of this Agreement.
        This Agreement may be changed only by a written agreement signed by an
        authorized agent of the party against whom enforcement is sought. Slab
        will not be bound by, and specifically objects to, any term, condition,
        or other provision that is different from or in addition to this
        Agreement (whether or not it would materially alter this Agreement) that
        is proffered by Customer in any receipt, acceptance, confirmation,
        correspondence, or otherwise, unless Slab specifically agrees to such
        provision in writing and signed by an authorized agent of Slab.
      </p>
    </section>
  </LegalLayout>
);

export default OrderFormTerms;
